<template>
    <Dropzone :options="dropzoneOptions" :useCustomSlot="true" ref="dropzoneComp" id="myVueDropzone">
        <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{ dropMessage }}</h3>
            <div class="subtitle">...sau click si alege manual</div>
        </div>
        <input type="hidden" name="token" value="xxx" />
    </Dropzone>
</template>

<script>
import isEmpty from 'lodash.isempty';
import Dropzone from 'vue2-dropzone';

export default {
    name: 'DropzoneComp',
    components: {
        Dropzone,
    },
    props: {
        config: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        'drop-message': {
            type: String,
            required: false,
            default: 'Trage aici fisierele!',
        },
    },
    data() {
        return {
            customConfig: {
                url: 'https://httpbin.org/post',
                paramName: 'upload',
                acceptedFiles: '.jpg,.gif,.jpeg,.png',
                maxNumberOfFiles: 5,
                maxFilesize: 2,
                maxFiles: 5,
                autoProcessQueue: false,
                useFontAwesome: false,
                addRemoveLinks: true,
                language: {
                    dictDefaultMessage: this.$t('publish.add.dropzone-msg'),
                    dictRemoveFile: this.$t('publish.add.dropzone-remove'),
                    dictMaxFilesExceeded: this.$t('publish.add.dropzone-max'),
                },
            },
            textVal: this.text,
        };
    },
    methods: {},
    computed: {
        dropzoneOptions() {
            if (!isEmpty(this.config)) {
                for (const i in this.config) {
                    this.customConfig[i] = this.config[i];
                }
            }
            return this.customConfig;
        },
    },
    created() {},
    beforeDestroy() {},
};
</script>

<style lang="scss">
@import './src/styles/modules/dropzone.scss';
</style>
